@import '../../styles/shared.scss';

.ant-dropdown-menu-title-content {
    flex: auto;
    font-size: 16px;
}

.ant-dropdown-menu-item-divider {
    background-color: $primary-color;
    height: 1px;
    line-height: 0;
    margin: 12px 0;
    overflow: hidden;
}

.user-menu-icon {
    background-color: white;
    color: $primary-color;
    margin: 0 auto;
    position: relative;
}

.user-menu-arrow {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
}

.user-menu-contents {
    background-color: $primary-light-color;
    border-radius: 0.5rem;
    border: 1px solid $primary-color;
    margin: 4px;
    padding: 8px;
}
