@import '../../../../styles/shared.scss';

.rate-viewer-container {
    background-color: #e9f0f0;
    border-radius: 0.75rem;
    margin-bottom: 24px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 104px;
    padding-top: 24px;

    @media screen and (max-width: $small) {
        padding: 12px;
    }
}

.rate-viewer-title {
    font-size: 16px;
}

.rate-viewer-pushdown {
    margin-top: 24px;
}

.rate-viewer-divider {
    border-bottom: 1px solid #99a0a3;
    margin: 2px 0;
}

.rate-viewer-currency {
    text-align: right;
}
