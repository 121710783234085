@import './styles/theme.scss';

.main-page-container {
    align-items: center;
    display: flex;
}

.main-page-content-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.main-page-loading-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.main-page-loading {
    color: $primary-color;
    font-size: 48px;
}
