.footer-text {
    color: white;
    margin: 0 12px;
}

a.footer-link {
    color: white;
    margin: 0 12px;
    text-decoration: none;
}
