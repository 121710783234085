@import './theme.scss';

.flex-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.view-card {
    border-color: $primary-color;
    border-radius: 0.75rem;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 5px 1px $shadow-color;

    @media screen and (max-width: $small) {
        width: 300px;
    }
}

.primary-text {
    color: $primary-color;
}

.bold,
.bold.ant-typography {
    font-weight: 700;
}

.extrabold,
.extrabold.ant-typography {
    font-weight: 900;
}

.small-text {
    font-size: 10px;
}

.flex-centered {
    align-items: center;
    display: flex;
    justify-content: center;
}

.text-centered {
    text-align: center;
}

.text-right-aligned {
    text-align: right;
}

.flex-container > button {
    height: auto;
    padding: 12px;
    position: relative;
    top: 48px;
    width: auto;
}

.flex-container > button > div > span {
    vertical-align: middle;
}

.flex-container > button > div > span + span {
    font-size: 18px;
    margin-left: 8px;
}
