@import '../../styles/shared.scss';

.contract-choice-container {
    flex-grow: 1;
    max-width: 1600px;
    min-width: 1280px;

    @media screen and (max-width: $small) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 320px;
        min-width: 320px;
    }
}

.contract-choice-upper-container {
    margin-bottom: 24px;
}

.contract-choice-form-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 680px;
    padding: 0 64px;
    width: 100%;

    @media screen and (max-width: $small) {
        min-width: 250px;
        padding: 0;
    }
}

.contract-choice-form-select {
    width: calc(100% - 48px);
}

.contract-choice-first {
    width: calc(50% - 24px);

    @media screen and (max-width: $small) {
        width: 100%;
    }
}

.contract-choice-second {
    margin: 0 24px;
    width: calc(50% - 24px);

    @media screen and (max-width: $small) {
        margin: 0;
        width: 100%;
    }
}

.contract-choice-card-container {
    display: flex;
    margin-bottom: 24px;
    width: 100%;
}

.flex-container .ant-col.ant-col-60 {
    display: flex;
    justify-content: center;
}

.passive-card {
    background-color: #ccc;
    margin-top: 24px;
}
