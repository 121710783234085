@import '../../../../styles/shared.scss';

.contract-card-header {
    background-color: $primary-color-op2;
    padding-bottom: 24px;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.contract-card-header .primary-text {
    font-size: 16px;
    font-weight: 500;
}

.contract-card-body {
    padding: 12px 24px;
    height: 280px;
}

.contract-card-content {
    height: 54px;
}

.contract-card-body .contract-card-content article {
    margin-left: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.selectable-card .radio-button-label {
    color: $primary-color;
}

.selectable-card:hover {
    box-shadow: 0 0 10px 2px $primary-color;
}

.selectable-card.selected-card {
    border-color: $primary-color;
    border-radius: 0.75rem;
    border-style: solid;
    border-width: 3px;
    margin-top: -2px;
}

.selectable-card.selected-card .contract-card-header {
    background-color: $primary-color;
}

.selectable-card.selected-card .radio-button-label,
.selectable-card.selected-card .contract-card-header .primary-text {
    color: white;
}

.selectable-card.selected-card .contract-card-body {
    background-color: $primary-color-op2;
}

.divider {
    border-top-color: gray;
    margin: 64px 0 24px 0;
}

.fontSize2rem {
    font-size: 2rem;
}

$highligh-color: #c43550;
$highligh-padding: 70px;
.highlighted-container {
    background-color: $highligh-color;
    border-radius: 0.75rem;
    padding-top: $highligh-padding;
}

.highlighted-card {
    border-color: $highligh-color !important;
    border-width: 3px !important;
    border-top-width: 1px !important;
}

.highlighted-card:hover {
    box-shadow: 0 0 10px 2px $highligh-color;
}

.selectable-card.selected-card.highlighted-card {
    margin-top: 0px;
}

.highlighted-title {
    color: white !important;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    height: 60px;
    margin-top: -60px;
}

@media screen and (min-width: calc($small + 1px)) {
    .not-highlighted-container {
        padding-top: $highligh-padding;
    }
}
