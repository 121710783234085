@import '../../../../styles/theme.scss';

.radio-button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
}

.radio-button-container-selected {
    color: $primary-color;
}

.radio-button-label {
    font-size: 20px;
    font-weight: 900;
    margin-top: 12px;
}
