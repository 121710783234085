@import '../../styles/shared.scss';

.login-page-container {
    flex-grow: 1;
    max-width: 680px;
    min-width: 680px;
    vertical-align: middle;

    @media screen and (max-width: $small) {
        max-width: 320px;
        min-width: 320px;
    }
}

.login-card-container {
    padding: 2em;
}

.login-title {
    margin-bottom: 1em !important;
}
