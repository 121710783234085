@import '../../main-layout/styles/theme.scss';

div.user-list-header {
    background-color: $primary-color;
    display: flex;
    padding: 4px 8px;

    h4 {
        color: white;
        flex-grow: 1;
        margin-bottom: 0;
    }
}

.user-list-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    padding: 4px;

    &:hover {
        background-color: $primary-color-op2;
    }
}

.user-list-item-selected {
    background-color: $primary-color-op5;
}

.user-list-item-name {
    margin-left: 16px;
    text-decoration: underline;
}
