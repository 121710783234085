.password-strength-bar-container {
    position: relative;
}

.password-strength-bar-elements {
    align-items: center;
    display: flex;
    margin: 5px 0 0;
}

.password-strength-bar-element {
    flex-basis: 0;
    flex-grow: 1;
    height: 2px;
    margin-right: 4px;
    max-width: 100%;
    position: relative;
    width: 100%;
}
