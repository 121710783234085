@import '../main-layout/styles/shared.scss';

.set-password-container {
    flex-grow: 1;
    max-width: 460px;
    min-width: $small;
    vertical-align: center;
}

.set-password-card-container {
    padding: 2em;
}

.set-password-title {
    margin-bottom: 1em !important;
}
