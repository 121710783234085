.user-edit-form-header {
    display: flex;

    h4 {
        flex-grow: 1;
    }

    button {
        margin-left: 8px;
    }
}
