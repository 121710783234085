@import '../../styles/shared.scss';

.data-gathering-card-container {
    flex-grow: 1;
    max-width: 1200px;
}

.data-gathering-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.data-gathering-form-pushdown {
    margin-top: 24px;
}

.data-gathering-form-bottom-space {
    margin-bottom: 24px;
}

.data-gathering-form-column-header {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
}

.data-gathering-form-column {
    display: flex;
    flex-wrap: wrap;
    min-width: 360px;
    padding: 0 2%;
    width: 48%;

    @media screen and (max-width: $small) {
        max-width: 250px;
        min-width: 250px;
        padding: 0;
    }
}

.data-gathering-form-left-halfwidth {
    width: calc(50% - 24px);

    @media screen and (max-width: $small) {
        margin-right: 24px;
        width: 100%;
    }
}

.data-gathering-form-left-halfwidth2 {
    width: calc(50% - 24px);
    margin: 0 24px;

    @media screen and (max-width: $small) {
        margin-bottom: 24px;
        margin-left: 0;
        margin-right: 24px;
        width: 100%;
    }
}

.data-gathering-form-left-fullwidth {
    margin-right: 24px;
    width: 100%;
}

.data-gathering-form-right-halfheight {
    max-height: 60%;
    width: 100%;
}

.data-gathering-form-right-fullwidth {
    width: 100%;
}
