@import './styles/theme.scss';

.header-container {
    display: flex;
}

.header-corner {
    background-color: #fff;
    box-shadow: 0 0 10px 1px $shadow-color;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
    height: 144px;
    left: 0;
    padding: 0 100px 0 50px;
    position: absolute;
    z-index: 1;
}

.header-corner-shadow {
    box-shadow: 0 0 10px 1px $shadow-color;
    height: 144px;
    left: 0;
    position: absolute;
    width: 250px;
}

.header-image {
    left: 320px;
    position: absolute;
}

.header-fill {
    flex: 1 0 0;
}

.header-menu {
    position: absolute;
    right: 8px;
}
